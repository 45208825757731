import React, { useContext, useEffect, useState } from "react"
import { LocationContext } from "../../../contexts/Location"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import { Radio } from "../../atoms/Radio"
import tw from "twin.macro"
import { setRadius } from "../../../contexts/Location/actions"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { FormView } from "../../../contexts/Contact/contact.d"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick } from "./TealiumHelpers"
import useVehicleData from "../../../hooks/Tealium/useVehicleData"

const SelectDealerView: React.FC<ViewProps> = ({
  active,
  nextView,
  showFormHeading,
}) => {
  const [{ zip, dealers, radius, dealersLoading }, dispatch] =
    useContext(LocationContext)
  const { _ } = useContext(LanguageContext)
  const [isInvalid, setIsInvalid] = useState(null)
  const [{ previousView, dealer, leadType, contactButtonType }, _dispatch] =
    useContext(ContactContext)
  const [dealerOptions, setDealerOptions] = useState([])

  // Fix defaultChecked showing
  useEffect(() => {
    setDealerOptions(dealers)
  }, [dealers])

  useEffect(() => {
    // Fix for defaultChecked dealer information not being populated
    // on initial load
    if (dealer?.DealerCode) {
      const defaultDealer = dealerOptions?.find(
        option => option.DealerCode === dealer.DealerCode,
      )
      _dispatch(updateField("dealer", defaultDealer))
    }
  }, [dealers])

  useEffect(() => {
    // If a user does not select a dealer and a dealer is not pre-selected, make sure the first dealer is selected
    !dealer && _dispatch(updateField("dealer", dealerOptions?.[0]))
    // Tealium
    updateContactDealerData({
      fromInventory: {
        ...contactDealerData.fromInventory,
      },
      dealerData: {
        dealer_name: dealerOptions?.[0]?.Name,
        dealer_code: dealerOptions?.[0]?.DealerCode,
      },
    })
  }, [dealerOptions])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, updateContactDealerData } = useTealiumContext()
  const [vehicleData] = useVehicleData()

  const handleLoadMore = (e: any) => {
    e.preventDefault()
    tealiumNavClick(
      "Select a Dealership to Continue",
      "Load More",
      trackTealEvent,
      contactDealerData,
      vehicleData,
    )
    if (radius === 25) {
      dispatch(setRadius(50))
    } else if (radius === 50) {
      dispatch(setRadius(100))
    }
  }

  const handleNextOnClick = () => {
    //if the dealer is prefilled based on allocation, still indicate the user has selected a dealer
    if (contactDealerData?.dealerData?.dealer_code == undefined) {
      updateContactDealerData({
        fromInventory: {
          ...contactDealerData.fromInventory,
        },
        dealerData: {
          dealer_name:
            contactDealerData?.allocatedDealer?.allocated_dealer_name ||
            dealer?.Name,
          dealer_code:
            contactDealerData?.allocatedDealer?.allocated_dealer_code ||
            dealer?.DealerCode,
        },
      })
      const prefilledDealer = {
        dealer_name:
          contactDealerData?.allocatedDealer?.allocated_dealer_name ||
          dealer?.Name,
        dealer_code:
          contactDealerData?.allocatedDealer?.allocated_dealer_code ||
          dealer?.DealerCode,
      }
      tealiumNavClick(
        "Select a Dealership to Continue",
        "Next",
        trackTealEvent,
        contactDealerData,
        vehicleData,
        null,
        prefilledDealer,
      )
    } else {
      tealiumNavClick(
        "Select a Dealership to Continue",
        "Next",
        trackTealEvent,
        contactDealerData,
        vehicleData,
        null,
        contactDealerData?.dealerData,
      )
    }
    if (!dealer) {
      setIsInvalid(true)
    } else {
      setIsInvalid(false)
      // Skipping SelectVehicleView if vehicle is preselected
      const seriesIsPreSelected =
        leadType === "New Inventory Availability" ||
        leadType === "Model & Trim Availability"
      let nextFormView: FormView = seriesIsPreSelected
        ? "NameView"
        : "IntroView"
      if (contactButtonType === "CheckAvailability")
        nextFormView = "CheckAvailabilityMethod"
      if (leadType === "Coming Soon") nextView = "CheckAvailabilityMethod"
      if (nextView) nextFormView = nextView

      // previousView is set when user clicks Change to change selected dealer
      _dispatch(setActiveView(previousView || nextFormView))
    }
  }

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        _dispatch(setActiveView("DealerLocationView"))
        tealiumNavClick(
          "Select a Dealership to Continue",
          "Back",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      }}
      nextOnClick={handleNextOnClick}
      heading={_("Select a Dealership to Continue")}
      formHeading={zip?.toString()}
      showFormHeading={showFormHeading}
    >
      <div css={tw`w-full max-w-[35rem] h-64 mt-6 overflow-y-scroll lg:h-40`}>
        {dealerOptions?.map((dealerOption, i) => (
          <Radio
            label={dealerOption.Name}
            value={dealerOption.DealerCode}
            name={"contactFormSelectDealer"}
            onChange={() => {
              _dispatch(updateField("dealer", dealerOption))
              tealiumNavClick(
                "Select a Dealership to Continue",
                dealerOption.Name,
                trackTealEvent,
                contactDealerData,
                vehicleData,
              )
              updateContactDealerData({
                fromInventory: {
                  ...contactDealerData.fromInventory,
                },
                dealerData: {
                  dealer_name: dealerOption.Name,
                  dealer_code: dealerOption.DealerCode,
                },
              })
            }}
            css={[
              tw`w-full px-4 py-3 normal-case bg-white`,
              i % 2 != 0 && tw`bg-gray-50`,
            ]}
            analytics-id={`${dealerOption.Name}:contact form:Select a Dealership to Continue`}
            defaultChecked={dealerOption?.DealerCode == dealer?.DealerCode}
          >
            <div css={tw`flex-grow grid grid-cols-2`}>
              <div>
                <p css={tw`font-bold`}>{dealerOption.Name}</p>
                {dealerOption.Distance && (
                  <p>
                    {dealerOption.Distance} {_("mi away")}
                  </p>
                )}
              </div>
              <div>
                <p>{dealerOption.Address1}</p>
                <p>{dealerOption.Address2}</p>
                <p>
                  <span>{dealerOption.City}, </span>
                  <span>{dealerOption.State} </span>
                  <span>{dealerOption.Zip}</span>
                </p>
              </div>
            </div>
          </Radio>
        ))}
        {radius != 100 && (
          <div css={tw`py-6 bg-white text-center`}>
            <button
              css={tw`text-red-400 inline-block text-sm uppercase`}
              onClick={e => handleLoadMore(e)}
              disabled={dealersLoading}
            >
              {dealersLoading ? `${_("LOADING")}...` : _("LOAD MORE")}
            </button>
          </div>
        )}
      </div>
      {/* Validation popover */}
      <section
        css={[
          tw`absolute -bottom-9 left-1/2 -translate-x-1/2 overflow-hidden transition-all duration-300`,
          isInvalid ? tw`max-h-[50px]` : tw`max-h-0`,
        ]}
      >
        <div
          css={[
            tw`bg-red-400 text-red-50 text-xs leading-3 normal-case whitespace-nowrap mx-auto mt-3 py-2 px-3 rounded-md`,
            // Pointed div
            tw`before:(absolute right-[calc(50% - 10px)] top-1.5 border-solid border-[10px] border-t-0 border-transparent border-b-red-400)`,
          ]}
        >
          {_("Please select a dealer.")}
        </div>
      </section>
    </FormPage>
  )
}

export default SelectDealerView
