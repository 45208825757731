import { SeriesAccessoriesProviderProps } from "./seriesaccessories"

export enum ACTION_TYPES {
  SET_SERIES_ACCESSORIES_DATA,
}

export const setSeriesAccessoriesData = (
  values: SeriesAccessoriesProviderProps,
) => {
  console.log("VALUES in ACTION", values)
  return {
    type: ACTION_TYPES.SET_SERIES_ACCESSORIES_DATA,
    payload: values,
  }
}
