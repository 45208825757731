import React from "react"
import { LocationProvider } from "./src/contexts/Location"
import { DisclaimersProvider } from "./src/contexts/Disclaimers"
import { DisclaimersModal } from "./src/components/molecules/DisclaimersModal"
import { LanguageProvider } from "./src/contexts/Language"
import { SearchProvider } from "./src/contexts/Search/context"
import { FavoritesProvider } from "./src/contexts/Favorites/context"
import { FamilySeriesProvider } from "./src/contexts/FamilySeries/context"
import { TealiumProvider } from "./src/contexts/Tealium"
import { Helmet } from "react-helmet"
import { HeaderProvider } from "./src/contexts/Header"
import { ContactProvider } from "./src/contexts/Contact"
import { ConfirmZipProvider } from "./src/contexts/ConfirmZip"
import { DealerDetailsPageContext } from "./src/templates/dealerDetails"
import { SeriesPageContextProvider } from "./src/contexts/SeriesPage"
import { ModelComparisonProvider } from "./src/contexts/ModelComparison/context"
import { SeriesAccessoriesContextProvider } from "./src/contexts/SeriesAccessories"

export const wrapRootElement = ({ element }) => (
  <>
    <Helmet
      bodyAttributes={{
        "chat-env": `${process.env.NODE_ENV === "production" ? "prod" : "qa"}`,
        "chat-language": "en",
        "chat-account": "explore.setbuyatoyota.com",
      }}
    />
    <LocationProvider>
      <SearchProvider>
        <FamilySeriesProvider>
          <SeriesPageContextProvider>
            <SeriesAccessoriesContextProvider>
              <ContactProvider>
                <FavoritesProvider>
                  <ModelComparisonProvider>
                    <ConfirmZipProvider>{element}</ConfirmZipProvider>
                  </ModelComparisonProvider>
                </FavoritesProvider>
              </ContactProvider>
            </SeriesAccessoriesContextProvider>
          </SeriesPageContextProvider>
        </FamilySeriesProvider>
      </SearchProvider>
    </LocationProvider>
  </>
)

export const wrapPageElement = ({ element, props }) => {
  let disclaimers = props?.data?.page?.disclaimers || []

  if (props?.pageContext?.lang === "es") {
    document.documentElement.lang = "es"
  } else {
    document.documentElement.lang = "en"
  }

  const disclaimersToOpen = new URLSearchParams(props.location.search).get(
    "disclaimers",
  )
  return (
    <DealerDetailsPageContext.Provider value={{ dealer: props.data?.dealer }}>
      <LanguageProvider language={props?.pageContext?.lang || "en"}>
        <DisclaimersProvider
          value={{
            disclaimers: disclaimers ? disclaimers : [],
            open: false,
            openOnLoad: disclaimersToOpen && disclaimersToOpen.split(","),
            language: props?.pageContext?.lang || "en",
          }}
        >
          <DisclaimersModal />
          <TealiumProvider>
            <HeaderProvider pageType={props?.pageContext?.type}>
              {element}
            </HeaderProvider>
          </TealiumProvider>
        </DisclaimersProvider>
      </LanguageProvider>
    </DealerDetailsPageContext.Provider>
  )
}

/**
 * @summary Adds equivalent of document.referrer since Gatsby does not set this. This was requested by 22Squared
 * @author Stu Finn
 */

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.locations = window.locations || [document.referrer]
  if (window.locations[locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href)
  }
  window.previousPath = window.locations[locations.length - 2]

  // tealium plugin workaround
  if (typeof window !== "undefined" && window.utag) {
    // Update Tealium on route change
    window.utag.view({ ...window.utag_data, page_path: location.pathname })
  }

  // Refresh inventory page if url params changed, but path did not. Fix solves navigating between the same series hybrid and non-hybrid inventory links.
  const pathChanged =
    prevLocation?.pathname && location?.pathname !== prevLocation.pathname
  const urlParamsChanged =
    prevLocation?.search && location?.search !== prevLocation.search
  if (
    location?.pathname?.includes("/inventory/") &&
    !pathChanged &&
    urlParamsChanged
  ) {
    window.location.reload()
  }
}
