import React, { FC, useContext } from "react"
import tw from "twin.macro"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { useTealiumContext } from "../../../contexts/Tealium"
import useDealers from "../../../hooks/useDealers"
import useDealerSearch from "../../../hooks/Tealium/useDealerSearch"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import Icon from "../../atoms/Icon"
import { UseMyLocationProps } from "./UseMyLocation.d"

const UseMyLocation: FC<UseMyLocationProps> = ({
  focusColor,
  fromBanner,
  setZipIsConfirmed,
  location,
  ...remainingProps
}): JSX.Element => {
  const [_state, _dispatch, updateLocationInfo] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)

  const { dealerSearch } = useDealerSearch()
  const { getDealerInfo } = useDealers()
  const { trackTealEvent } = useTealiumEvent()
  const dealerInfo = getDealerInfo()
  const { analyticsId } = remainingProps
  const { tealPageData, setZipEvent, lifestyleLanderTealData } =
    useTealiumContext()
  const handleSubmit = async () => {
    // Update confirmedZip local storage value if from banner (or from confirm zip modal)
    if (fromBanner) {
      LocalStorageClient.write("confirmedZip", true)
      LocalStorageClient.delete("sourceZip")
      setZipIsConfirmed(true)
    }

    // Update the location context.
    // If the visitor is not "out of area", fire a tealium event:
    try {
      const res = await updateLocationInfo()
      if (res?.isOutOfRange?.value) {
        return
      }
      // If the zipcode does not change between uses of "use my location":
      if (_state?.zip?.toString() === res?.zip?.toString()) {
        analyticsId === "top nav:nearby dealers"
          ? trackTealEvent({ customer_zip: res?.zip?.toString() })
          : (location === "modal" &&
              tealPageData.page_type == "inventory srp") ||
            location === "inventory"
          ? setZipEvent({
              inProgress: true,
              prevZip: _state?.zip?.toString(),
              searchInput: res?.zip?.toString(),
              isGlobal: false,
            })
          : tealPageData.page_type == "tact"
          ? // Triggers a zipcode_go Tealium event with filtered Dealers for TACT page
            setZipEvent({
              location: "tactPage",
              inProgress: true,
              prevZip: _state?.zip?.toString(),
              searchInput: res?.zip?.toString(),
              isGlobal: false,
            })
          : trackTealEvent({
              tealium_event: "zipcode_go",
              dealer_name: dealerInfo.dealerNames,
              dealer_code: dealerInfo.dealerCodes,
              search_results: dealerInfo.searchResults,
              customer_zip: res?.zip?.toString(),
              ...(tealPageData.offer_type && {
                offer_type: tealPageData.offer_type,
              }),
              ...(tealPageData.page_type == "lifestyle" && {
                vehicle_category: lifestyleLanderTealData.selectedCategories,
              }),
            })
        return
      }
      if (location === "inventory") {
        trackTealEvent({
          tealium_event: "zipcode_go",
          customer_zip: res?.zip?.toString(),
        })
      } else if (
        location === "modal" &&
        tealPageData.page_type == "inventory srp"
      ) {
        // Triggers a zipcode_go Tealium event
        setZipEvent({
          inProgress: true,
          prevZip: _state?.zip?.toString(),
          searchInput: res?.zip?.toString(),
          isGlobal: false,
        })
      } else if (tealPageData.page_type == "tact") {
        // Triggers a zipcode_go Tealium event with filtered Dealers for TACT page
        setZipEvent({
          location: "tactPage",
          inProgress: true,
          prevZip: _state?.zip?.toString(),
          searchInput: res?.zip?.toString(),
          isGlobal: false,
        })
      } else {
        // Otherwise, if the geolocated zipcode is different than the one in use:
        setZipEvent({
          inProgress: true,
          prevZip: _state?.zip?.toString(),
          searchInput: res?.zip?.toString(),
          isGlobal: false,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <button
      css={[
        tw`flex items-center text-xs ml-4`,
        tw`focus-visible:(outline-white)`,
        focusColor === "gray" && tw`focus-visible:outline-gray-50`,
      ]}
      onClick={() => handleSubmit()}
      analytics-id={`use my location:${analyticsId}:`}
    >
      <Icon.Location
        color="red-400"
        css={[tw`-rotate-90 h-4 w-4 mr-2`, tw`md:(rotate-0 h-6 w-6)`]}
      />
      <span css={[tw`whitespace-nowrap text-red-400`]}>
        {_("Use My Location")}
      </span>
    </button>
  )
}

export default UseMyLocation
