import React, { useContext, useState, useEffect, useRef } from "react"
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api"
import { LocationContext } from "../../../contexts/Location"
import { setSelectedDealer } from "../../../contexts/Location/actions"
import DealerTooltip from "./DealerTooltip"
import { DealerMapProps } from "./DealerMap.d"
import { Button } from "../../atoms/Button"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import useDealers from "../../../hooks/useDealers"
import { useTealiumContext } from "../../../contexts/Tealium"
import { scrollIntoView } from "seamless-scroll-polyfill"

const DealerMap: React.FC<DealerMapProps> = ({
  allowPan,
  nextRadius,
  mapContainerStyle,
  showSearchButton,
  ...remainingProps
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  })

  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  )
  const [
    { selectedDealer, dealers, dealersLoading },
    dispatch,
    updateLocationInfo,
  ] = useContext(LocationContext)

  const [map, setMap] = useState(null)

  let center = {
    lat: 33.5186, // default center
    lng: 86.8104, // default center
  }

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      dealers?.map(dealer => {
        bounds.extend({ lat: dealer.Latitude, lng: dealer.Longitude })
        return dealer.Id
      })

      // Don't zoom in too far on only one marker
      if (bounds?.getNorthEast()?.equals(bounds?.getSouthWest())) {
        var extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01,
        )
        var extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01,
        )
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }

      map.fitBounds(bounds)
    }
  }, [dealers, map])

  const [dealerInfo, setDealerInfo] = useState(null)

  useEffect(() => {
    // Update dealerInfo when dealers or selectedDealer change
    const updatedDealerInfo = getDealerInfo()
    setDealerInfo(updatedDealerInfo)
  }, [dealers, selectedDealer])

  const { tealPageData, setRefineInAction } = useTealiumContext()
  const { getDealerInfo } = useDealers()
  const { trackTealEvent } = useTealiumEvent()

  const [mapLoaded, setMapLoaded] = useState(false)

  const onLoad = React.useCallback(
    map => {
      const bounds = new window.google.maps.LatLngBounds(center)
      dealers?.forEach(dealer => {
        bounds.extend({ lat: dealer.Latitude, lng: dealer.Longitude })
      })

      // Don't zoom in too far on only one marker
      if (bounds?.getNorthEast()?.equals(bounds?.getSouthWest())) {
        var extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01,
        )
        var extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01,
        )
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }
      if (selectedDealer) {
        center = {
          lat: selectedDealer.Latitude, // default center
          lng: selectedDealer.Longitude, // default center
        }
      }
      map.fitBounds(bounds)
      setMap(map)
      setMapLoaded(true)
    },
    [dealers],
  )

  const onUnmount = React.useCallback(map => {
    setMap(null)
  }, [])

  const handleMarkerClick = (index: number) => {
    setSelectedMarkerIndex(index)
    dispatch(setSelectedDealer(dealers[index].Id))

    // Change the center of the map to the position of the clicked marker
    const dealer = dealers[index]
    const newCenter = { lat: dealer.Latitude, lng: dealer.Longitude }
    map.panTo(newCenter)
  }

  const { _ } = useContext(LanguageContext)
  const [mapInitialized, setmapInitialized] = useState(false)
  const [mapInteracted, setMapInteracted] = useState(false)

  useEffect(() => {
    if (map && mapLoaded && mapInitialized) {
      // Add event listeners for dragend and zoom_changed events
      map.addListener("dragend", () => setMapInteracted(true))
      map.addListener("zoom_changed", () => setMapInteracted(true))
    }
  }, [map, mapLoaded, mapInitialized])

  const updatedDealerInfo = getDealerInfo()
  const firstLoad = useRef(null)
  const handleUpdate = async (locationInfo: string) => {
    firstLoad.current = true
    await updateLocationInfo(locationInfo)
  }
  useEffect(() => {
    if (!dealersLoading && firstLoad.current) {
      setRefineInAction({ inProgress: false })
      firstLoad.current = false
      trackTealEvent({
        tealium_event: "refinement",
        refinement_value: `${tealPageData.page_type}|${tealPageData.page_name}|search this area`,
        search_results: updatedDealerInfo.searchResults,
        dealer_name: updatedDealerInfo.dealerNames,
        dealer_code: updatedDealerInfo.dealerCodes,
      })
    }
  }, [dealersLoading])

  const scrollToTop = () => {
    if (window && typeof window !== "undefined") {
      requestAnimationFrame(() => {
        const element = document.getElementById("dealerTop")
        if (element) {
          console.log("Element found:", element)
          scrollIntoView(element, {
            behavior: "smooth",
            inline: "start",
            block: "start",
          })
          console.log("scrollIntoView called")
          // Adjust the scroll position manually
          window.scrollTo(0, 0)
        } else {
          console.log("Element not found")
        }
      })
    }
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle ? mapContainerStyle : containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={center}
      options={{
        disableDefaultUI: true,
        gestureHandling: allowPan ? "auto" : "none",
        styles: styles,
      }}
      {...remainingProps}
    >
      {dealers?.map((dealer, i) => {
        const markerPosition = { lat: dealer.Latitude, lng: dealer.Longitude }
        // Create a variable for marker's position

        const label =
          nextRadius < 4
            ? {
                text: (i + 1).toString(),
                color: "white",
                fontSize: "11.5px",
              }
            : undefined

        return (
          <React.Fragment key={i}>
            <Marker
              position={markerPosition}
              onClick={() => handleMarkerClick(i)}
              icon={{
                url:
                  selectedDealer && selectedDealer.Id === dealer.Id
                    ? "/marker_red.svg"
                    : "/marker_black.svg",
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(10, 10),
                labelOrigin: new google.maps.Point(10, 10),
              }}
              label={label}
            />
            {selectedMarkerIndex === i && (
              <InfoWindow
                position={markerPosition} // Set the position prop for InfoWindow
                onCloseClick={() => setSelectedMarkerIndex(null)}
              >
                <DealerTooltip dealer={dealer} i={i} />
              </InfoWindow>
            )}
          </React.Fragment>
        )
      })}
    </GoogleMap>
  ) : (
    <></>
  )
}

const containerStyle = {
  width: "100%",
  height: "100%",
}

const styles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f1f3f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
]

export default React.memo(DealerMap)
