export const tealiumNavClick = (
  contactQuestion: string,
  clickText: string,
  trackTealEvent: any,
  contactDealerData: any,
  vehicleData?: any,
  zip?: number,
  dealerInfo?: { dealer_name: string; dealer_code: string },
) => {
  // Selected vehicle data (contactDealerData.fromInventory) should overwrite page vehicle data (vehicleData), so it is spread _after_ vehicleData.
  contactDealerData?.fromInventory != null
    ? trackTealEvent({
        tealium_event: "contact_form_nav",
        contact_question: contactQuestion,
        dropdown_selected: clickText,
        ...contactDealerData.fromInventory,
        ...(zip && {
          customer_zip: zip,
        }),
        ...vehicleData,
        ...contactDealerData.fromInventory,
        ...contactDealerData?.allocatedDealer,
        ...contactDealerData?.dealerData,
        ...dealerInfo,
      })
    : contactDealerData.fromSelection != null
      ? trackTealEvent({
          tealium_event: "contact_form_nav",
          contact_question: contactQuestion,
          dropdown_selected: clickText,
          ...contactDealerData.fromSelection,
          ...(zip && {
            customer_zip: zip,
          }),
          ...dealerInfo,
        })
      : trackTealEvent({
          tealium_event: "contact_form_nav",
          contact_question: contactQuestion,
          dropdown_selected: clickText,
          ...(zip && {
            customer_zip: zip,
          }),
          ...vehicleData,
          ...dealerInfo,
        })
}

// Note: `dealerInfo` should be explicitly passed into tealiumSubmit, because it's not always required even if it is present as a property of contactDealerData
export const tealiumSubmit = (
  tealiumEvent: string,
  contactQuestion: string,
  customerEmail: string,
  trackTealEvent: any,
  contactDealerData: any,
  dealerInfo?: { dealer_name: string; dealer_code: string },
  moreData?: {},
) => {
  contactDealerData?.fromInventory != null
    ? trackTealEvent({
        tealium_event: tealiumEvent,
        contact_question: contactQuestion,
        customer_email: customerEmail,
        ...contactDealerData.fromInventory,
        ...(tealiumEvent == "thank_you_page" &&
          contactDealerData.allocatedDealer),
        ...contactDealerData.dealerData,
        ...contactDealerData?.allocatedDealer,
        ...dealerInfo,
      })
    : contactDealerData.fromSelection != null
    ? trackTealEvent({
        tealium_event: tealiumEvent,
        contact_question: contactQuestion,
        customer_email: customerEmail,
        ...contactDealerData.fromSelection,
        ...dealerInfo,
        ...moreData,
      })
    : trackTealEvent({
        tealium_event: tealiumEvent,
        contact_question: contactQuestion,
        customer_email: customerEmail,
        ...dealerInfo,
        ...moreData,
      })
}
