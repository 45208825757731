import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { SelectedVehicleViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import SelectField from "./SelectField"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick } from "./TealiumHelpers"
import useVehicleData from "../../../hooks/Tealium/useVehicleData"
import { vehicleCategory } from "../../../helpers"

type TrimsSelection = { name: string; year: number }

const SelectVehicleView: React.FC<SelectedVehicleViewProps> = ({
  active,
  seriesData,
  modelsData,
  prevView = null,
  showFormHeading,
}) => {
  const [
    { seriesName, vehicle, trim, isFavoritesContactDealerModalOpen },
    dispatch,
  ] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  const [sortedSeriesData, setSortedSeriesData] = useState(null)
  const notSureText = _("I'm not sure")

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, updateContactDealerData } = useTealiumContext()
  const [vehicleData] = useVehicleData()

  // If on series page- populate model value on form
  useEffect(() => {
    if (!isFavoritesContactDealerModalOpen) {
      vehicle?.series?.name &&
        dispatch(updateField("seriesName", vehicle?.series?.name))

      vehicle?.trim && dispatch(updateField("trim", vehicle?.trim))
    }
  }, [vehicle])

  // Stores all models of selected series (based on seriesName)
  const [modelOptions, setModelOptions] = useState(
    modelsData?.filter((node: any) => {
      const name =
        typeof seriesName === "string" ? seriesName : seriesName?.name
      return node?.fieldValue.toLowerCase() === name?.toLowerCase()
    })?.nodes,
  )
  // When seriesName changes (user selects another series) models state changes to models of newly selected series
  useEffect(() => {
    let options = []
    if (modelsData && seriesName) {
      const models = modelsData?.filter((node: any) => {
        if (typeof seriesName === "string")
          return node?.fieldValue.toLowerCase() === seriesName?.toLowerCase()
        return (
          node?.fieldValue.toLowerCase() === seriesName?.name?.toLowerCase()
        )
      })
      if (models?.length > 0) {
        //remove null models
        const sanitizedModels = models[0]?.nodes?.filter(
          node => node?.name != null,
        )
        // sort models alphabetically
        sanitizedModels &&
          (options = sanitizedModels?.sort(
            (a: { name: string }, b: { name: string }) =>
              a?.name?.localeCompare(b?.name),
          ))
      }
    }
    setModelOptions(removeDuplicateTrims(options))
  }, [seriesName])

  /**
   *  Updated model options to remove duplicate trims.
   *  Filters out trims that are not the latest two years.
   */
  const removeDuplicateTrims = (options: TrimsSelection[]) => {
    if (!options || options.length === 0) return []
    const latestYear = Array.from(
      new Set(options.map(item => item.year).sort((a, b) => b - a)),
    )
    const lastTwoYears = options
      .filter(
        item =>
          item.year >= (latestYear.length > 1 ? latestYear[1] : latestYear[0]),
      )
      .filter(t => !t.name.includes("-trimmed"))
    return lastTwoYears
  }

  // Sort series alphabetically
  useEffect(() => {
    const additionalSeries = [
      { name: "Crown Signia", currentYear: 2024 },
      { name: "Land Cruiser ", currentYear: 2024 },
    ]
    const sortedSeriesData = seriesData
      .filter(
        series =>
          ![
            "Avalon",
            "Avalon Hybrid",
            "C-HR",
            "Crown",
            "Land Cruiser",
          ].includes(series.name) && !series.name.includes("Hybrid"),
      )
      .concat(additionalSeries)
      ?.sort((a, b) => a?.name?.localeCompare(b?.name))

    sortedSeriesData.unshift({ name: notSureText, currentYear: 0 })
    setSortedSeriesData(sortedSeriesData)
  }, [seriesData])

  // Model validation - a model is required when a series is selected
  const [showModelVal, setShowModelVal] = useState(false)

  const handleClickNext = () => {
    // Requiring model selection when series is selected and there are models available to select
    const modelNotSelected = modelOptions?.length > 0 && !trim
    // For tealium call - Start
    const { vehicle_model } = contactDealerData?.fromSelection || {}
    const modelAndVehicleSelected = vehicle_model
    const neitherSelected = !vehicle_model
    // End - for tealium call
    dispatch(updateField("vehicle", null))

    if (modelAndVehicleSelected) {
      tealiumNavClick(
        "Were you interested in a specific vehicle?",
        "Next",
        trackTealEvent,
        contactDealerData,
      )
    } else if (neitherSelected) {
      tealiumNavClick(
        "Were you interested in a specific vehicle?",
        "Skip",
        trackTealEvent,
        contactDealerData,
      )
    }
    dispatch(setActiveView("NameView"))
  }

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        dispatch(setActiveView(prevView ? prevView : "IntroView")),
          tealiumNavClick(
            "Were you interested in a specific vehicle?",
            "Back",
            trackTealEvent,
            contactDealerData,
            vehicleData,
          )
      }}
      nextOnClick={handleClickNext}
      nextButtonText={seriesName ? null : _("Skip")}
      analyticsHeading={"Were you interested in a specific vehicle?"}
      showFormHeading={showFormHeading}
      css={tw`px-4 z-50`}
    >
      <h3 css={[tw`text-center text-2xl font-semibold normal-case mb-3`]}>
        {_("Were you interested in a specific vehicle?")}
      </h3>
      <SelectField
        active={active}
        options={sortedSeriesData}
        onChange={([value]) => {
          dispatch(updateField("seriesName", value))
          dispatch(updateField("trim", null))
          trackTealEvent({
            tealium_event: "dropdown_interaction",
            contact_question: "Were you interested in a specific vehicle?",
            dropdown_selected: value,
            vehicle_model_truncated: value,
            vehicle_segment_vehicle_page: vehicleCategory(value),
            vehicle_year: sortedSeriesData?.filter(
              series => series.name == value,
            )[0].currentYear,
          })
          updateContactDealerData({
            fromInventory: null,
            fromSelection: {
              vehicle_model: value,
              vehicle_year: sortedSeriesData?.filter(
                series => series.name == value,
              )[0].currentYear,
              vehicle_model_truncated: seriesName,
              vehicle_segment_vehicle_page: vehicleCategory(seriesName),
            },
          })
        }}
        fieldName="series"
        label="Select"
        value={[seriesName, seriesName || "Select"]} // Display Select when series is null
        css={[tw`max-w-xs`]}
        analytics-id={`${
          seriesName || "Select"
        }:contact form:Were you interested in a specific vehicle?`}
      />

      {modelOptions?.length > 0 && (
        <>
          <h3
            css={[tw`text-center text-2xl font-semibold normal-case mt-6 mb-3`]}
          >
            {_("Select a Trim ")}
            <span css={[tw`font-book text-red-400 italic text-lg`]}>
              {_("(Optional)")}
            </span>
          </h3>
          <SelectField
            active={active}
            showVal={showModelVal}
            options={modelOptions}
            onChange={([value]) => {
              dispatch(updateField("trim", value))
              setShowModelVal(false)
              trackTealEvent({
                tealium_event: "dropdown_interaction",
                contact_question: "Were you interested in a specific vehicle?",
                dropdown_selected: value,
                vehicle_model_truncated: seriesName,
                vehicle_trim: value,
                vehicle_segment_vehicle_page: vehicleCategory(seriesName),
                vehicle_year: modelOptions?.filter(
                  model => model.name == value,
                )[0].year,
              })
              updateContactDealerData({
                fromInventory: null,
                fromSelection: {
                  vehicle_model: seriesName,
                  vehicle_trim: value,
                  vehicle_year: modelOptions?.filter(
                    model => model.name == value,
                  )[0].year,
                  vehicle_model_truncated: seriesName,
                  vehicle_segment_vehicle_page: vehicleCategory(seriesName),
                },
              })
            }}
            fieldName="model"
            label={_("Select")}
            // validationMessage={_("Please select a trim.")}
            value={[trim, trim?.toUpperCase() || _("Select")]} // Display Select when trim is null
            css={[tw`max-w-xs uppercase`]}
            analyticsId={`${
              trim || "Select"
            }:contact form:Were you interested in a specific vehicle?`}
          />
        </>
      )}
    </FormPage>
  )
}

export default SelectVehicleView
